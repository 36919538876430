import { PropsWithChildren } from 'react';
import { LuAlertTriangle } from 'react-icons/lu';

export default function ErrorMessage({ children }: PropsWithChildren) {
  return (
    <p className="form-error-message py-1 px-2 bg-red-700 z-10 text-white flex flex-row gap-2 items-center text-xs font-bold">
      <LuAlertTriangle className="w-4 h-4" />

      <span>{children}</span>
    </p>
  );
}
