'use client';

import Link, { LinkProps } from 'next/link';
import { twMerge } from 'tailwind-merge';

import { buttonClasses } from './button.component';
import { ButtonSize, ButtonVariant } from '@/lib/state';

// TODO: How do we extend this in a way that lets us include AnchorHTMLAttributes
// without having to individually include them all here? (e.g. id)
interface Props extends LinkProps {
  className?: string;
  title?: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  children: React.ReactNode;
}

export default function LinkButton({
  className,
  variant,
  size,
  children,
  title,
  ...otherProps
}: Props) {
  return (
    <Link
      prefetch={false}
      className={twMerge(buttonClasses(variant, size), className)}
      title={title}
      {...otherProps}
    >
      {children}
    </Link>
  );
}
