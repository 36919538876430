import { twMerge } from 'tailwind-merge';

export default function StaffCardSkeleton({ className }: { className?: string }) {
  return (
    <div className={twMerge('rigel-staff-card-skeleton flex-col w-full relative flex', className)}>
      <div className="w-full h-80 bg-slate-200 animate-pulse" />

      <div className="p-6 bg-light-grey-striped">
        <div className="flex flex-col justify-around gap-6 w-full">
          <div className="w-full h-9 rounded bg-slate-200 animate-pulse" />
          <div className="w-40 h-6 rounded bg-slate-200 animate-pulse" />
          <div className="w-20 h-6 rounded bg-slate-200 animate-pulse" />
        </div>
      </div>
    </div>
  );
}
