import { ButtonVariant, ContainerWidth } from '@/lib/state';
import { ComponentPropsWithRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends ComponentPropsWithRef<'div'> {
  children: React.ReactNode;
  className?: string;
  containerWidth?: ContainerWidth;
}
export const MAX_WIDTH = 1686;

export default function Container({
  children,
  className = '',
  containerWidth = ContainerWidth.Large,
  ...otherProps
}: Props): JSX.Element {
  let widthClassName = '';

  switch (containerWidth) {
    case ContainerWidth.Large:
      widthClassName = 'max-w-screen-3xl';
      break;
    case ContainerWidth.Medium:
      widthClassName = 'max-w-screen-xl';
      break;
    case ContainerWidth.Small:
      widthClassName = 'max-w-screen-lg';
      break;
  }

  return (
    <div
      className={twMerge(
        containerWidth !== ContainerWidth.Full && `relative w-full mx-auto px-6 ${widthClassName}`,
        className
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
}
